* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background-left: #0f172a;
  --background-right: #f9fafb;
  --text: #bcb7ae;

  --blue-100: #66b2ff;
  --blue-200: #3399ff;
  --blue-500: #007fff;
  --blue-800: #001e3c;

  --gray-600: #676561;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

body,
html {
  width: 100%;
  height: 100%;
}

body,
input,
select,
textarea,
button {
  font: 400 1rem 'Poppins', sans-serif;
}

button {
  cursor: pointer;
}

a {
  color: var(--text);
  text-decoration: none;
}

ul,
li {
  list-style: none;
}
